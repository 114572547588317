<template>
  <a-card :bordered="false">
    <div class="card-title"><b>{{ !isEmpty(vehicle) ? vehicle.vehicle_name : '' }}</b>{{ $route.meta.title }}</div>
    <div class="table-operator">
      <div>统计方式：
        <a-radio-group v-model="queryParam.bill_type" @change="handleRefresh(true)">
          <a-radio :value="1">
            年度统计
          </a-radio>
          <a-radio :value="0">
            月度统计
          </a-radio>
        </a-radio-group>
      </div>
      <template v-if="queryParam.bill_type===0">
        <div style="margin: 10px 0">
          当前年份：
          <a-date-picker
              format="YYYY年"
              mode="year"
              :value="queryParam.yearValue"
              :open="isopen"
              placeholder="选择年份"
              @change="onChange"
              @openChange="openChange"
              @panelChange="panelChange"
          />
        </div>
        <div style="margin: 10px 0">
          指定月份（不勾选默认展示全部月份数据）：
          <a-checkbox-group
              v-model="queryParam.monthValue"
              :options="plainOptions"
              @change="handleRefresh(true)"
          />
        </div>
      </template>
    </div>
    <template v-if="!isEmpty(vehicle)">
      <s-table
          ref="table"
          rowKey="vb_id"
          :loading="isLoading"
          :columns="columns"
          :data="loadData"
          :pageSize="15"
          :scroll="{y:450}"
      >
        <div slot="price" slot-scope="text, item">
          <span style="color: rgb(208, 5, 5)">¥</span> {{ text }}
        </div>
        <div class="actions" slot="action" slot-scope="text, item">
          <a v-action:log @click="handleCommission(item)">费用明细</a>
        </div>
      </s-table>
    </template>
    <template v-else>
      <div style="height: 500px;display: flex;align-items: center;justify-content: center">
        <a-empty>
          <span slot="description"> 请在左侧车辆列表中点击您需要查看的车辆 </span>
        </a-empty>
      </div>
    </template>
    <CommissionModel
        ref="CommissionModel"
    />
  </a-card>
</template>

<script>
import * as Api from '@/api/statistics/vehicleBill'
import {STable} from '@/components'
import CommissionModel from "./modules/CommissionModel";
import {isEmpty} from "../../../utils/util";
import moment from "moment";

export default {
  name: 'Index',
  components: {
    STable,
    CommissionModel,
  },
  data() {
    return {
      // 标题
      title: '',

      loading: false,

      isopen: false,
      plainOptions: [
        {label: '1月', value: '01'},
        {label: '2月', value: '02'},
        {label: '3月', value: '03'},
        {label: '4月', value: '04'},
        {label: '5月', value: '05'},
        {label: '6月', value: '06'},
        {label: '7月', value: '07'},
        {label: '8月', value: '08'},
        {label: '9月', value: '09'},
        {label: '10月', value: '10'},
        {label: '11月', value: '11'},
        {label: '12月', value: '12'},
      ],
      // 查询参数
      queryParam: {
        yearValue: moment(),
        monthValue: '',
        vehicle_id: '',
        bill_type: 1,
      },
      // 正在加载
      isLoading: false,
      // 表头
      columns: [
        {
          title: '车牌号码',
          width: 200,
          dataIndex: 'vehicle_name',
        },
        {
          align: 'center',
          title: '时间',
          width: 100,
          dataIndex: 'month_str',
          scopedSlots: {customRender: 'month_str'}
        },
        {
          title: '总营收',
          width: 100,
          dataIndex: 'total_revenue',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: '总开支',
          width: 100,
          dataIndex: 'total_expenditure',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: '利润',
          width: 100,
          dataIndex: 'profit',
          scopedSlots: {customRender: 'price'}
        },
        {
          title: '操作',
          width: 300,
          dataIndex: 'action',
          scopedSlots: {customRender: 'action'}
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      },
      vehicle: {},
    }
  },
  created() {
  },
  methods: {
    onChange() {
      this.queryParam.yearValue = null
    },
    openChange(isopen) {
      this.isopen = !!isopen
    },
    panelChange(value) {
      this.queryParam.yearValue = value
      this.isopen = false
      this.handleRefresh(true)
    },

    isEmpty,
    setVehicle(item) {
      this.queryParam.vehicle_id = item.vehicle_id
      this.vehicle = item
      this.$nextTick(() => {
        this.handleRefresh(true)
      })
    },

    /**
     * 费用明细
     */
    handleCommission(item) {
      this.$refs.CommissionModel.queryParam.vehicle_id = item.vehicle_id
      this.$refs.CommissionModel.queryParam.vb_id = item.vb_id
      this.$refs.CommissionModel.queryParam.month_time = item.month_time
      this.$refs.CommissionModel.queryParam.bill_type = item.bill_type
      this.$refs.CommissionModel.open(item)
    },

    /**
     * 关闭弹窗
     */
    handleCancel() {
      this.visible = false
    },

    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },

    /**
     * 检索查询
     */
    onSearch() {
      this.handleRefresh(true)
    }

  }
}
</script>
