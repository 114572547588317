<template>
  <a-modal
      :title="title"
      :width="1070"
      :visible="visible"
      :confirmLoading="loading"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
      @cancel="cancel"
  >
    <a-spin :spinning="loading">
        <s-table
            ref="table"
            rowKey="vr_id"
            :loading="isLoading"
            :columns="columns"
            :data="loadData"
            :pageSize="15"
            :scroll="{y:500}"
        >
          <div slot="jyd" slot-scope="text, item">
            <b>{{ text == 0 ? '仓库' : '加油站' }}</b>
          </div>

          <div slot="jflx" slot-scope="text, item">
            <b v-if="item.jyd==1">{{ text == 0 ? '油卡加油' : '现金加油' }}</b>
            <b v-else>无</b>
          </div>
          <div slot="status" slot-scope="text, item">
            <a-tag v-if="item.status==0">待审核</a-tag>
            <a-tag color="green" v-if="item.status==1">审核通过</a-tag>
            <template v-if="item.status==2">
              <a-tag color="red">
                审核驳回
              </a-tag>
              <br>
              <span style="color: red">{{ item.reject_text }}</span>
            </template>
          </div>
          <div slot="number" slot-scope="text, item">
            {{ text }}{{ item.tool_unit || 'L' }}
            <a-tag color="green" v-if="item.status_type==1">起始标记</a-tag>
            <a-tag color="blue" v-if="item.status_type==2">结束标记</a-tag>
          </div>
          <div slot="amount_type" slot-scope="text, item">
            <template v-if="item.jyd==1">
              <a-tag color="green" v-if="item.amount_type==0">自行垫付</a-tag>
              <a-tag color="blue" v-if="item.amount_type==1">公司已付</a-tag>
            </template>
          </div>
          <div slot="price" slot-scope="text, item">
            <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}/{{ item.tool_unit }}
          </div>
          <div slot="total_amount" slot-scope="text, item">
            <span style="color: rgb(208, 5, 5)">￥</span>{{ text }}
          </div>

          <div slot="source" slot-scope="text, item">
            {{ text == 1 ? '后台' : '用户' }}
          </div>
          <div slot="kilometers" slot-scope="text, item">
            <div v-if="text">{{ text }}km</div>
          </div>
          <div class="actions" slot="action" slot-scope="text, item">
            <a @click="handleEdit(item)">编辑</a>
            <a-popconfirm
                title="确认删除当前记录？"
                ok-text="确定"
                cancel-text="取消"
                @confirm="handleDelete(item)"
            >
              <a href="#">删除</a>
            </a-popconfirm>
          </div>
        </s-table>
        <SaveForm :vehicleId="vehicle_id" ref="SaveForm" @handleSubmit="handleRefresh"/>
        <FillRejectText ref="FillRejectText" @handleSuccess="onAuditNoPass"/>
    </a-spin>
  </a-modal>
</template>
<script>
import * as Api from '@/api/vehicle/refuelLog'
import {STable, FillRejectText} from "@/components";
import SearchForm from "./modules/SearchForm";
import SaveForm from "./modules/SaveForm";
import pred from '@/views/vehicle/index/pre/Index.vue'

export default {
  name: "ShowToolList",
  components: {SearchForm, FillRejectText, SaveForm, STable, pred},
  data() {
    return {
      yh: 0,
      start_vr_id: 0,
      end_vr_id: 0,
      title: '加油记录',
      queryParam: {},
      visible: false,
      loading: false,
      isLoading: false,
      vehicle_id: "",
      columns: [
        {
          title: '审核状态',
          dataIndex: 'status',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'status'}
        },
        {
          title: '加油点',
          dataIndex: 'jyd',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'jyd'}
        },

        {
          title: '缴费类型',
          dataIndex: 'jflx',
          align: 'center',
          width: 100,
          scopedSlots: {customRender: 'jflx'}
        },
        {
          title: '加油数量',
          dataIndex: 'jysl',
          align: 'center',
          width: 100,


          scopedSlots: {customRender: 'number'}
        },
        {
          title: '加油金额',
          dataIndex: 'amount',
          align: 'center',
          width: 100,

          scopedSlots: {customRender: 'total_amount'}
        },
        // {
        //   title: '加油金额类型',
        //   dataIndex: 'amount_type',
        //   align: 'center',
        //   width: 100,
        //   scopedSlots: {customRender: 'amount_type'}
        // },
        {
          title: '车辆仪表公里数',
          dataIndex: 'kilometers',
          align: 'center',
          width: 100,

          scopedSlots: {customRender: 'kilometers'}
        },
        {
          title: '加油时间',
          dataIndex: 'create_time',
          align: 'center',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 140,
          scopedSlots: {customRender: 'action'}
        },
      ],
      currVrId: '',
      // 加载数据方法 必须为 Promise 对象
      loadData: param => {
        return Api.list({...param, ...this.queryParam})
            .then(response => {
              return response.data.list
            })
      }
    }
  },
  methods: {
    open(item) {
      this.queryParam.is_statistics = 1
      this.queryParam.vehicle_id = item.vehicle_id
      this.queryParam.curr_yearmonth = item.curr_yearmonth
      this.visible = true
      // this.vehicle_id = vehicle_id
      // this.$nextTick(() => {
      //   this.handleRefresh(true)
      // })
    },
    cancel() {
      this.visible = false
    },

    /**
     * 添加
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑
     */
    handleEdit(item) {
      this.$refs.SaveForm.edit(item)
    },
    onAuditNoPass(text) {
      Api.audit({vrId: this.currVrId, form: {status: 2, reject_text: text}})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },
    handleDelete(item) {
      Api.deleted({vrId: item['vr_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.handleRefresh()
          })
    },

    /**
     * 搜索
     */
    handleSeachSubmit(value) {
      this.queryParam = {
        ...this.queryParam,
        ...value
      }
      this.handleRefresh(true)
    },


    /**
     * 刷新列表
     * @param Boolean bool 强制刷新到第一页
     */
    handleRefresh(bool = false) {
      this.$refs.table.refresh(bool)
    },
  }
}
</script>
<style scoped lang="less">

</style>
